.amount {
display: inline;
}

.react-datepicker-wrapper {
width: auto;
}

label {
    margin: 3.6px;
}

html {
background-color: skyblue;
background-image: url('https://www.ktu.edu.tr/image/logo/birim/tr.png');
background-blend-mode: difference;
}

input {
background-color: beige;
outline-style: double;
outline-width: 3px;
}
body {
width: 600px;
outline-style: dashed;
background-color: beige;
}
.parent {
margin-block-start: 50%;
}
.react-datepicker__month-container {
background-color: beige;
}
